import * as React from 'react'
import theme from 'src/themes/theme'
import { Typography } from '@mui/material'

interface Props {
  children: React.ReactNode
  color?: string
  sx?: any
}

const Header: React.FC<Props> = ({ children, color, sx={} }) => {
  const styles = {
    header: {
      color: color ? color : theme.palette.grey[700],
      fontSize: 25,
      fontWeight: '700'
    }
  }
  return <Typography sx={[styles.header, sx]}>{children}</Typography>
}

export default Header
