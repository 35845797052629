import { useCallback, useContext, useEffect, useState } from "react";
import {
    TextField,
    Button,
    Container,
    Link,
    Grid,
    IconButton,
    InputAdornment,
    FormHelperText,
    Typography,
    Box,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import EmailIcon from "@mui/icons-material/Email";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LogoSVG from "src/assets/images/logo";
// import Logo from "src/assets/images/evento_sem_fila.png";
import BackgroundImage from "src/assets/images/bg_image.jpg";
// import BackgroundImage from "src/assets/images/bground.jpg";
import GoogleLogo from "src/assets/images/google.png";
import AxiosClient from "src/clients/axios.client";
import ContextWrapper from "src/context/context/wrapper";
import theme from "src/themes/theme";
import { AccountModel } from "src/data/models";

const Login = () => {
    const { setIsAuth } = useContext(ContextWrapper["isAuth"]());
    const { setActiveMenu } = useContext(ContextWrapper["activeMenu"]());
    const { setCurrentUser } = useContext(ContextWrapper["currentUser"]());
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleGoogleLogin = async () => {
        window.location.href = `${process.env.REACT_APP_API}/api/auth/google`;
    };

    const getUser = useCallback(
        async (id: string) => {
            try {
                setLoading(true);
                const user = await AxiosClient.getAccount(id);
                if (user.status === 200) {
                    saveUserInLStorage(user.data);
                    setCurrentUser(user.data);
                    setIsAuth(true);
                    setActiveMenu("Eventos");
                    setLoading(false);
                    return;
                }
                setLoading(false);
                setError("Falha de autenticação");
            } catch (error) {
                setError("Falha de autenticação. E-mail ou senha inválida");
                setLoading(false);
            }
        },
        [setActiveMenu, setCurrentUser, setIsAuth, setLoading]
    );

    const getUserByToken = async (accessToken: string) => {
        try {
            setLoading(true);
            const user = await AxiosClient.getUser({ accessToken });
            if (user.status === 200) {
                saveUserInLStorage(user.data);
                setCurrentUser(user.data);
                setIsAuth(true);
                setActiveMenu("Eventos");
                setLoading(false);
                return;
            }
            setLoading(false);
            setError("Falha de autenticação");
        } catch (error) {
            setError("Falha de autenticação. E-mail ou senha inválida");
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        const userId = localStorage.getItem("id");
        if (userId) {
            getUser(userId);
        } else {
            setIsAuth(false);
            setLoading(false);
        }
    }, [getUser, setActiveMenu, setIsAuth, setLoading]);

    useEffect(() => {
        // Pega o token da URL
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get("accessToken");
        if (accessToken) {
            localStorage.setItem("token", accessToken);
            getUserByToken(accessToken);
        }
    }, [getUserByToken]);

    const saveUserInLStorage = (user: AccountModel) => {
        localStorage.setItem("token", user.accessToken);
        localStorage.setItem("email", user.email);
        localStorage.setItem("firstName", user.firstName);
        localStorage.setItem("id", user.id);
        localStorage.setItem("role", user.role);
        localStorage.setItem("avatar", user?.avatar || "");
        localStorage.setItem("isAuth", "true");
    };

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            setError("");
            if (!email) {
                setError("O campo email está vazio");
                return;
            }

            if (!password) {
                setError("O campo senha está vazio");
                return;
            }
            setLoading(true);
            const user = await AxiosClient.login({ username: email, password });
            if (user.status === 200) {
                saveUserInLStorage(user.data);
                setCurrentUser(user.data);
                setIsAuth(true);
                setActiveMenu("Eventos");
                setLoading(false);
                return;
            }
            setLoading(false);
            setError("Falha de autenticação");
        } catch (error) {
            setError("Falha de autenticação. E-mail ou senha inválido");
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                height: "100vh",
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Container component="main" maxWidth="xs">
                <Box sx={styles.logoBox}>
                    <LogoSVG color="white" size={200} />
                </Box>
                <Box
                    style={{
                        padding: "40px 20px",
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    {/* <Box sx={styles.logoBox}>
                        <img src={Logo} alt="Logo" style={styles.logo} />
                    </Box> */}
                    <Typography sx={styles.title}>Entrar</Typography>

                    <Typography sx={styles.message}>
                        Olá! Insira suas credenciais para continuar
                    </Typography>
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="E-mail"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EmailIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                maxLength: 70,
                            }}
                            style={{ marginBottom: "20px" }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: "20px" }}
                        />
                        {error && (
                            <FormHelperText
                                error
                                style={{ marginBottom: "10px", textAlign: "center" }}
                            >
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{
                                padding: "10px 0",
                                marginBottom: "20px",
                                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            }}
                        >
                            Entrar
                        </Button>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            sx={{ gap: 2, paddingBottom: 2 }}
                        >
                            <Box sx={{ flexGrow: 1, borderBottom: "1px solid gray" }} />
                            <Typography sx={{ color: "gray" }}>ou</Typography>
                            <Box sx={{ flexGrow: 1, borderBottom: "1px solid gray" }} />
                        </Box>
                        <Button
                            onClick={handleGoogleLogin}
                            fullWidth
                            variant="contained"
                            style={{
                                padding: "10px 0",
                                marginBottom: "20px",
                                backgroundColor: "#ffffff",
                                color: "#757575",
                                borderRadius: "4px",
                                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "none",
                                fontWeight: "bold",
                            }}
                        >
                            <img
                                src={GoogleLogo}
                                alt="Google logo"
                                style={{ width: "20px", marginRight: "10px" }}
                            />
                            Entrar com Google
                        </Button>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link
                                    href="/reset-password"
                                    variant="body2"
                                    style={{ color: blue[600] }}
                                >
                                    Esqueceu a senha?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    href="/signup"
                                    variant="body2"
                                    style={{ color: blue[600] }}
                                >
                                    Não tem uma conta? Cadastre-se
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                    <Typography sx={styles.eventosemfila}>
                        Evento Sem Fila @{new Date().getFullYear()}
                    </Typography>
                </Box>
            </Container>
        </div>
    );
};

const styles = {
    eventosemfila: {
        fontSize: 12,
        color: theme.palette.grey[500],
        paddingTop: 1,
    },
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
        width: "100%",
    },
    message: {
        marginBottom: "20px",
        textAlign: "left",
        width: "100%",
    },
    logoBox: {
        marginBottom: "20px",
        position: "absolute",
        left: 0,
        top: 0,
    },
    logo: {
        width: "150px",
    },
};

export default Login;
