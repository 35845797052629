import React, {
  useState,
  useMemo,
  ChangeEvent,
  useEffect,
  useCallback,
} from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import "./UpdateAttendeeModal.css";
import { Help as HelpIcon } from "@mui/icons-material";

import Button from "src/components/Button";
import Header from "src/components/Header";
import styles from "./styles";
import { CustomInputField, CustomSelectInputField } from "src/components/TextField";
import { validateAndFormatCpf } from "src/utils/validateAndFormatCpf";
import AxiosClient from "src/clients/axios.client";
import { phoneNumberMask } from "src/utils/phoneNumberMask";
import { keepOnlyNumbers } from "src/utils/keepOnlyNumbers";
import { AttendeeModel, EventModel } from "src/data/models";
import { responsiveStyles } from "src/themes/breakpoints";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  submit: (data: any) => void;
  selectedAttendee: AttendeeModel;
  event: EventModel;
}

const UpdateAttendeeModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  submit,
  selectedAttendee,
  event,
}) => {
  const theme = useTheme();
  const responsive = responsiveStyles(theme);
  
  const [error, setError] = useState("");
  const [errorCpf, setErrorCpf] = useState("");
  const [, setLoading] = useState(false)
  const [attendee, setAttendee] = useState<AttendeeModel>({
    ...selectedAttendee,
    phone: selectedAttendee.phone
      ? phoneNumberMask(selectedAttendee.phone.replace("+55", ""))
      : "",
  });
  const [attendeeList, setAttendeeList] = useState<{ rows: AttendeeModel[], count: number }>({ rows: [], count: 0 });

  const isDisabled = useMemo(() => {
    const disable =
      attendee.firstName === "" || (attendee.cpf !== "" && !!errorCpf);
    return disable;
  }, [attendee.firstName, attendee.cpf, errorCpf]);

  const handleClose = (): void => {
    setShowModal(false);
  };
  const handleSubmit = (): void => {
    const { userDetails, ...rest } = attendee;
    submit({
      ...rest,
      phone: rest.phone ? `+55${keepOnlyNumbers(rest.phone)}` : null,
      numberOfCompanions: attendee.attendeeType === 'companion' ? 0 : attendee.numberOfCompanions
    });
  };

  const handleChange = (val: string | boolean, prop: string) => {
    setAttendee((prevState) => ({
      ...prevState,
      [prop]: val,
    }));
    if (error) setError("");
  };

  const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { isValid, formattedCpf } = validateAndFormatCpf(event.target.value);
    if (isValid || event.target.value.length < 14) {
      handleChange(formattedCpf, "cpf");
      setErrorCpf("");
    } else {
      setErrorCpf("CPF inválido");
      handleChange(formattedCpf, "cpf");
    }
  };

  const handlePhone = (event: { target: { value: any } }) => {
    let value = event.target.value;
    setAttendee((prevState) => ({
      ...prevState,
      phone: phoneNumberMask(value),
    }));
  };

  const getAttendee = useCallback(async () => {
    const params = { id: selectedAttendee.attendeeId };
    AxiosClient.getAttendee(params)
      .then((resp) => {
        setAttendee({
          ...resp.data,
          phone: resp.data.phone
            ? phoneNumberMask(resp.data.phone.replace("+55", ""))
            : undefined,
        });
      })
      .catch(() => enqueueSnackbar("Erro ao atualizar.", { variant: "error" }));
  }, [selectedAttendee]);

  useEffect(() => {
    getAttendee();
  }, [getAttendee]);

  const getAttendees = useCallback(async () => {
    setLoading(true)
    const params = {
      eventId: event.id,
      page: 0,
      pageSize: 1000,
      attendeeType: 'guest',
      // hasAvailableCompanions: true,
    }
    console.log('params: ', params)
    AxiosClient.getAttendeeList(params)
      .then((resp) => {
        setAttendeeList(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Erro ao buscar participantes.", { variant: "error" });
        setLoading(false);
      });
  }, [event.id])

  useEffect(() => {
    if (attendee.attendeeType === 'companion' && !attendeeList.count) {
      getAttendees()
    }
  }, [attendee, attendeeList.count, getAttendees])

  return (
    <Modal open={showModal} onClose={handleClose}>
      <Box sx={{
        ...styles.viewModal,
        ...responsive.modalWidth,
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]} sx={responsive.fontSize}>
            Atualizar Cadastro do Participante
          </Header>
        </Box>
        <Box sx={{
          ...styles.innerContainer,
          ...responsive.containerPadding,
        }}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: responsive.fieldWidth.width },
            }}
          >
            <Box sx={{
              ...styles.row,
              ...responsive.rowDirection,
            }}>
              <CustomInputField
                required
                placeholder="Ex: João da Silva"
                label="Nome do Participante:"
                value={attendee?.firstName}
                onChange={(e) => handleChange(e.target.value, "firstName")}
                maxLength={100}
              />
              <CustomInputField
                label="Sobrenome"
                placeholder="Sobrenome.."
                value={attendee?.lastName}
                onChange={(e) => handleChange(e.target.value, "lastName")}
                maxLength={70}
              />
              <CustomInputField
                label="Apelido"
                placeholder="Apelido.."
                value={attendee?.nickName}
                onChange={(e) => handleChange(e.target.value, "nickName")}
                maxLength={70}
              />
            </Box>

            <Box sx={{
              ...styles.row,
              ...responsive.rowDirection,
            }}>
              <CustomInputField
                label="Email"
                placeholder="Ex: joao@gmail.com"
                value={attendee?.email}
                onChange={(e) => handleChange(e.target.value, "email")}
                maxLength={70}
              />
              <CustomInputField
                label="Celular"
                placeholder="Ex: (XX) XXXXX XXXX"
                value={attendee?.phone}
                onChange={handlePhone}
              />
              <CustomInputField
                label="CPF"
                placeholder="Ex: XXX.XXX.XXX-XX"
                value={attendee?.cpf}
                onChange={handleChangeCpf}
                error={!!errorCpf}
                helperText={errorCpf}
              />
            </Box>
            <Box sx={{
              ...styles.row,
              ...responsive.rowDirection,
            }}>
              <CustomSelectInputField
                label="Status de  Confirmação de Presença:"
                value={attendee?.registrationStatus}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event.target.value, "registrationStatus");
                }}
              >
                <MenuItem key="confirmed" value="confirmed">
                  Confirmado
                </MenuItem>
                <MenuItem key="pending" value="pending">
                  Pendente
                </MenuItem>
                <MenuItem key="canceled" value="canceled">
                  Cancelado
                </MenuItem>
              </CustomSelectInputField>

              <CustomInputField
                label="Necessidades de Acessibilidade"
                placeholder="Ex: rampa"
                value={attendee?.accessibilityNeeds}
                onChange={(e) =>
                  handleChange(e.target.value, "accessibilityNeeds")
                }
                maxLength={500}
              />
            </Box>
            <Box sx={{
              ...styles.row,
              ...responsive.rowDirection,
            }}>
              <CustomSelectInputField
                label="Tipo de Participante:"
                value={attendee?.attendeeType}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event.target.value, "attendeeType");
                }}
              >
                <MenuItem key="guest" value="guest">
                  Convidado
                </MenuItem>
                <MenuItem key="companion" value="companion">
                  Acompanhante
                </MenuItem>
              </CustomSelectInputField>

              {attendee?.attendeeType === 'guest' ? (
                <CustomSelectInputField
                  label="Quantidade de Acompanhantes:"
                  value={attendee?.numberOfCompanions}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.value, 'numberOfCompanions');
                  }}
                >
                  {[...Array(10).keys()].map((val) => <MenuItem key={val.toString()} value={val}>
                    {val}
                  </MenuItem>)}
                </CustomSelectInputField>
              ) : (
                <CustomSelectInputField
                  label="Participante Principal:"
                  value={attendee?.associatedToAttendeeId}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.value, "associatedToAttendeeId");
                  }}
                >
                  {attendeeList.rows.map((item) => (
                    <MenuItem key={item.attendeeId} value={item.attendeeId}>
                      {item.firstName} {item.lastName || item.nickName || ''}
                    </MenuItem>
                  ))}
                </CustomSelectInputField>
              )}
            </Box>
            <Box sx={{
              ...styles.row,
              ...responsive.rowDirection,
            }}>
              <CustomInputField
                label="Notas"
                placeholder="Informações adicionais do participante"
                value={attendee?.internalNotes}
                onChange={(e) => handleChange(e.target.value, "internalNotes")}
                maxLength={500}
              />
            </Box>
            {event.maxAgeForFreeAdmission > 0 && (
              <Box sx={[
                styles.row, 
                responsive.rowDirection,
                { paddingTop: 1, paddingLeft: 1 }
              ]}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={!!attendee.isChild}
                      onChange={(e) => {
                        console.log('e.target.value: ', e.target.checked)
                        handleChange(e.target.checked, "isChild")
                      }}
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          ...responsive.fontSize,
                          color: theme.palette.grey[700],
                        }}
                      >
                        O participante possui idade igual ou inferior a{" "}
                        {event.maxAgeForFreeAdmission} anos
                      </Typography>
                      <Tooltip title="Idade limite para entrada franca">
                        <HelpIcon sx={styles.helpIcon} />
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
            )}
          </Box>

          <Stack
            direction={responsive.buttonStack.flexDirection}
            spacing={responsive.buttonStack.gap}
            justifyContent={{
              xs: 'center',
              sm: 'flex-end',
            }}
            sx={{ 
              paddingTop: 2,
              width: '100%',
              '& > button': {
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              },
            }}
          >
            <Button
              variant="outlined"
              bgColor="transparent"
              onClick={handleClose}
            >
              Fechar
            </Button>
            <Button onClick={handleSubmit} isDisable={isDisabled}>
              Atualizar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateAttendeeModal;
