import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
    ChangeEvent,
} from "react";
import { format } from "date-fns";
import {
    Box,
    Button as MUIButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    TablePagination,
    InputBase,
    Divider,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Tooltip,
    TextField,
    MenuItem,
    useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import { AttendeeModel, EventModel } from "src/data/models";
import {
    Search as SearchIcon,
    Feed as FeedIcon,
    UploadFile as UploadFileIcon,
    DeleteForever as DeleteForeverIcon,
    Refresh as RefreshIcon,
    PersonAdd as PersonAddIcon,
    Send as SendIcon,
    FileDownload as FileDownloadIcon,
    Star as StarIcon,
    PeopleOutline as PeopleOutlineIcon,
    Stroller as StrollerIcon,
} from "@mui/icons-material";
import NoFoundData from "src/components/NoFoundData";
import { CreateAttendeeModal } from "./components/CreateAttendeeModal";
import { UpdateAttendeeModal } from "./components/UpdateAttendeeModal";
import DeleteAttendeeModal from "./components/DeleteAttendeeModal/DeleteAttendeeModal";
import DetailsAttendeeModal from "./components/DetailsAttendeeModal/DetailsAttendeeModal";
import FileUploadModal from "./components/CreateAttendeesModal/CreateAttendeesModal";
import AxiosClient from "src/clients/axios.client";
import { CreateAttendee, UpdateAttendee } from "src/clients/types";
import styles from "./styles";
import ContextWrapper from "src/context/context/wrapper";
import responsiveStyles from "src/themes/breakpoints";

const actions = [
    { icon: <FeedIcon />, name: "Ver Mais" },
    { icon: <SendIcon />, name: "Solicitar Confirmação" },
    { icon: <DeleteForeverIcon />, name: "Deletar" },
];

const Attendees: React.FC = () => {
    const theme = useTheme();
    const responsive = responsiveStyles(theme);

    const { setLoading } = useContext(ContextWrapper["loading"]());
    const { selectedEvent } = useContext(ContextWrapper["selectedEvent"]());

    const [list, setList] = useState<{ rows: AttendeeModel[]; count: number }>({
        rows: [],
        count: 0,
    });
    const [showCreateAttendeeModal, setShowCreateAttendeeModal] = useState<boolean>(false);
    const [selectedAttendee, setSelectedAttendee] = useState<AttendeeModel | null>(null);
    const [attendeeName, setAttendeeName] = useState<string>("");
    const [showUpdateAttendeeModal, setShowUpdateAttendeeModal] = useState<boolean>(false);
    const [showDeleteAttendeeModal, setShowDeleteAttendeeModal] = useState<boolean>(false);
    const [showDetailsAttendeeModal, setShowDetailsAttendeeModal] = useState<boolean>(false);
    const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [registrationStatus, setRegistrationStatus] = useState<"confirmed" | "pending" | "canceled" | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [event, setEvent] = useState<EventModel>({
        agenda: [""],
        budget: 0,
        userId: "",
        cancellationReason: "",
        createdAt: "",
        description: "",
        internalNotes: "",
        dressCode: "",
        endAt: new Date(),
        startAt: new Date(),
        eventType: "",
        id: "",
        isCancelled: false,
        isRSVPRequired: true,
        isTicketed: false,
        location: "",
        registrationDeadline: new Date(),
        speakers: [""],
        sponsors: [""],
        theme: "",
        maxTicketQuantity: 0,
        title: "",
        updatedAt: "",
        maxAgeForFreeAdmission: 0,
        isPrivate: false,
        ticketsCreated: 0,
    });

    const isEventCancelled = useMemo(() => event?.isCancelled, [event]);
    const isEventExpired = useMemo(() => {
        const endAt = new Date(event.endAt);
        const currentDate = new Date();
        return endAt < currentDate;
    }, [event]);

    const handleChangePage = (e: any, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleRegistrationStatus = (val: any) => setRegistrationStatus(val);
    const handleShowCreateAttendeeModal = () => setShowCreateAttendeeModal(true);
    const handleShowUploadFileModal = (val: boolean) => setShowUploadFileModal(val);

    const handleActions = (actionName: string, val: AttendeeModel) => {
        setSelectedAttendee(val);
        switch (actionName) {
            case "Deletar":
                setShowDeleteAttendeeModal(true);
                break;
            case "Solicitar Confirmação":
                sendConfirmation();
                break;
            case "Ver Mais":
                setShowUpdateAttendeeModal(true);
                break;
            default:
        }
    };

    const handleSubmit = () => {
        if (page === 0) getAttendees();
        else {
            setPage(0);
            setRowsPerPage(5);
        }
    };

    const triggerDownload = (download: any, fileName: any) => {
        const link = document.createElement("a");
        link.href = download;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownload = () => {
        setLoading(true);
        const params = { page: 0, pageSize: 10000, eventId: selectedEvent };
        AxiosClient.downloadAttendeeList(params)
            .then((response) => {
                const { download, fileName } = response.data;
                triggerDownload(download, fileName);
                setLoading(false);
            })
            .catch((err) => {
                enqueueSnackbar("Erro ao baixar lista.", { variant: "error" });
                setLoading(false);
            });
    };

    const getAttendees = () => {
        if (!selectedEvent) return;
        setLoading(true);
        const params = {
            page: +page,
            pageSize: +rowsPerPage,
            eventId: selectedEvent,
            firstName: attendeeName,
            registrationStatus: registrationStatus,
            includeCompanions: true,
        };
        AxiosClient.getAttendeeList(params)
            .then((resp) => {
                setList(resp.data);
                setLoading(false);
            })
            .catch((err) => {
                enqueueSnackbar("Erro ao buscar participantes.", { variant: "error" });
                setLoading(false);
            });
    };

    const submitCreateAttendee = async (params: CreateAttendee) => {
        try {
            setLoading(true);
            await AxiosClient.createAttendee(params);
            setLoading(false);
            setShowCreateAttendeeModal(false);
            enqueueSnackbar("Sucesso", { variant: "success" });
            getAttendees();
        } catch (error: any) {
            enqueueSnackbar(error?.response?.data?.error?.message || "Erro ao criar participante.", { variant: "error" });
            setLoading(false);
        }
    };

    const submitUpdateAttendee = async (data: UpdateAttendee) => {
        try {
            setLoading(true);
            const httpResponse = await AxiosClient.updateAttendee(data);
            setLoading(false);
            if (httpResponse.status === 200) {
                getAttendees();
                setShowUpdateAttendeeModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao atualizar.", { variant: "error" });
            }
        } catch (error: any) {
            enqueueSnackbar(error?.response?.data?.error?.message || "Erro ao atualizar.", { variant: "error" });
            setLoading(false);
        }
    };

    const submitCreateAttendees = async (file: File) => {
        try {
            if (!selectedEvent || !file) return;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("eventId", selectedEvent);
            setLoading(true);
            await AxiosClient.createAttendees(formData);
            setLoading(false);
            getAttendees();
            setShowUploadFileModal(false);
            enqueueSnackbar("Sucesso", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Erro ao criar paricipantes!", { variant: "error" });
            setLoading(false);
        }
    };

    const submitDeleteAttendee = async () => {
        try {
            if (!selectedAttendee?.attendeeId) return;
            setLoading(true);
            const httpResponse = await AxiosClient.deleteAttendee(selectedAttendee?.attendeeId);
            setLoading(false);
            if (httpResponse.status === 200) {
                getAttendees();
                setShowDeleteAttendeeModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao deletar!", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Erro ao deletar!", { variant: "error" });
            setLoading(false);
        }
    };

    const sendConfirmation = async () => {
        try {
            if (!selectedAttendee?.attendeeId) return;
            await AxiosClient.requestPresenceConfirmation({ attendeeId: selectedAttendee.attendeeId });
            enqueueSnackbar("Solicitação de confirmação foi enviada", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Erro ao solicitar confirmação de presença", { variant: "error" });
        }
    };

    const sendConfirmationToPendings = async () => {
        try {
            if (!selectedEvent) return;
            await AxiosClient.requestPresenceConfirmationToPendings({ eventId: selectedEvent });
            enqueueSnackbar("Solicitações de confirmação de presença enviadas com sucesso", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Erro ao solicitar confirmações de presença", { variant: "error" });
        }
    };

    const getEvent = useCallback(async () => {
        await AxiosClient.getEventById({ eventId: selectedEvent, setLoading, setEvent });
    }, [selectedEvent, setLoading]);

    useEffect(() => {
        if (selectedEvent) getEvent();
    }, [getEvent, selectedEvent]);

    useEffect(() => {
        getAttendees();
    }, [page, rowsPerPage, selectedEvent, registrationStatus]);

    const formatDate = (date: string | Date) => format(new Date(date), "dd/MM/yy HH:mm");

    const Row: React.FC<{ row: AttendeeModel; guestName?: string }> = ({ row, guestName }) => (
        <TableRow key={row.attendeeId}>
            <TableCell sx={responsive.fontSize}>
                <Box sx={{ display: "flex", alignItems: "center", ...(row.attendeeType === "companion" ? { paddingLeft: 3 } : {}) }}>
                    <Tooltip title={row.attendeeType === "guest" ? "Convidado" : `Acompanhante do(a) ${guestName}`}>
                        {row.attendeeType === "guest" ? <StarIcon sx={{ fontSize: 18, paddingRight: 0.5 }} /> : <PeopleOutlineIcon sx={{ fontSize: 18, paddingRight: 0.5 }} />}
                    </Tooltip>
                    {row.isChild && <Tooltip title="Criança"><StrollerIcon sx={{ fontSize: 18, paddingRight: 0.5 }} /></Tooltip>}
                    {row.firstName}
                </Box>
            </TableCell>
            <TableCell sx={responsive.fontSize}>{row.attendeeType === "guest" ? row.numberOfCompanions : "-"}</TableCell>
            {event?.isRSVPRequired && <TableCell sx={responsive.fontSize}>{row.registrationStatus === "pending" ? "Pendente" : row.registrationStatus === "confirmed" ? "Confirmado" : "Cancelado"}</TableCell>}
            <TableCell sx={responsive.fontSize}>{row.phone || "-"}</TableCell>
            {event?.isRSVPRequired && <TableCell sx={responsive.fontSize}>{row.registrationStatus === "confirmed" ? formatDate(row.registrationDate) : "-"}</TableCell>}
            <TableCell>
                <Box sx={{ width: "0px" }}>
                    <SpeedDial ariaLabel="SpeedDial basic example" direction="left" sx={styles.speedDial} icon={<SpeedDialIcon />}>
                        {actions.map((action, index) => {
                            if ((isEventCancelled || isEventExpired) && action.name === "Solicitar Confirmação") return null;
                            if (action.name === "Solicitar Confirmação" && row.registrationStatus === "confirmed") return null;
                            return (
                                <SpeedDialAction
                                    key={index}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => handleActions(action.name, row)}
                                />
                            );
                        })}
                    </SpeedDial>
                </Box>
            </TableCell>
        </TableRow>
    );

    return (
        <Box sx={{ ...responsive.containerPadding, maxHeight: "90vh", overflowY: "auto", overflowX: "hidden" }}>
            <Stack
                direction={responsive.rowDirection.flexDirection}
                spacing={0}
                justifyContent="space-between"
                sx={{ marginBottom: 5, gap: responsive.rowDirection.gap, flexWrap: "wrap" }}
            >
                <Stack
                    direction={responsive.rowDirection.flexDirection}
                    spacing={0}
                    justifyContent="space-between"
                    sx={{ gap: responsive.rowDirection.gap, width: { xs: "100%", sm: "auto" } }}
                >
                    <Paper
                        component="form"
                        sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            ...responsive.fieldWidth,
                            boxShadow: "none",
                            border: "1px solid",
                            borderColor: "grey.400",
                            height: "34px",
                            borderRadius: "2px",
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1, ...responsive.fontSize }}
                            onChange={(e) => setAttendeeName(e?.target.value)}
                            placeholder="Pesquisar participante..."
                            inputProps={{ "aria-label": "pesquisar participante" }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip title="Pesquisar">
                            <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions" onClick={handleSubmit}>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                    {event.isRSVPRequired && (
                        <TextField
                            sx={{ ...styles.textField, ...responsive.fieldWidth }}
                            id="registrationStatus"
                            fullWidth
                            select
                            label="Status de Confirmação"
                            size="small"
                            value={registrationStatus}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleRegistrationStatus(event.target.value)}
                        >
                            <MenuItem key={Math.random()} value={undefined}>None</MenuItem>
                            <MenuItem key={Math.random()} value="confirmed">Confirmado</MenuItem>
                            <MenuItem key={Math.random()} value="pending">Pendente</MenuItem>
                            <MenuItem key={Math.random()} value="canceled">Cancelado</MenuItem>
                        </TextField>
                    )}
                </Stack>
                <Stack
                    direction={responsive.buttonStack.flexDirection}
                    spacing={1}
                    sx={{
                        ...responsive.buttonStack,
                        "& .MuiButton-root": {
                            minWidth: { xs: "40px", sm: "48px" }, // Tamanho mínimo dos botões
                            p: { xs: "6px", sm: "8px" },         // Padding reduzido em xs
                        },
                    }}
                >
                    {list.count && (
                        <Tooltip title="Baixar lista de participantes">
                            <MUIButton variant="contained" onClick={handleDownload} sx={{ backgroundColor: "#D94923" }}>
                                <FileDownloadIcon />
                            </MUIButton>
                        </Tooltip>
                    )}
                    <Tooltip title={isEventCancelled ? "Evento Cancelado" : isEventExpired ? "Evento Expirado" : "Cadastrar Participantes"}>
                        <MUIButton
                            variant="contained"
                            onClick={() => handleShowUploadFileModal(true)}
                            disabled={isEventCancelled || isEventExpired}
                            sx={{ backgroundColor: "#089000" }}
                        >
                            <UploadFileIcon />
                        </MUIButton>
                    </Tooltip>
                    <Tooltip title="Atualizar">
                        <MUIButton variant="contained" onClick={() => getAttendees()}>
                            <RefreshIcon />
                        </MUIButton>
                    </Tooltip>
                    <Tooltip title={isEventCancelled ? "Evento Cancelado" : isEventExpired ? "Evento Expirado" : "Solicitar confirmação de presença para os participantes pendentes"}>
                        <MUIButton
                            disabled={isEventCancelled || isEventExpired}
                            variant="contained"
                            onClick={() => sendConfirmationToPendings()}
                        >
                            <SendIcon />
                        </MUIButton>
                    </Tooltip>
                    <Tooltip title={isEventCancelled ? "Evento Cancelado" : isEventExpired ? "Evento Expirado" : "Cadastrar Participante"}>
                        <MUIButton
                            variant="contained"
                            onClick={handleShowCreateAttendeeModal}
                            disabled={isEventCancelled || isEventExpired}
                        >
                            <PersonAddIcon />
                        </MUIButton>
                    </Tooltip>
                </Stack>
            </Stack>
            {list.count ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={responsive.fontSize}>Nome</TableCell>
                                    <TableCell sx={responsive.fontSize}>Acompanhantes</TableCell>
                                    {event?.isRSVPRequired && <TableCell sx={responsive.fontSize}>Confirmação</TableCell>}
                                    <TableCell sx={responsive.fontSize}>Celular</TableCell>
                                    <TableCell sx={responsive.fontSize}>Mais</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.rows.map((attendee) => (
                                    <>
                                        <Row row={attendee} />
                                        {attendee.companions?.length ? attendee.companions.map((companion) => <Row row={companion} guestName={attendee.firstName} />) : null}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                        <Table sx={styles.table}>
                            <TableFooter sx={styles.tableFooter}>
                                <TableRow>
                                    <TablePagination
                                        sx={styles.tablePagination}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={list.count}
                                        rowsPerPage={+rowsPerPage}
                                        page={+page}
                                        SelectProps={{ inputProps: { "aria-label": "Rows per page" }, native: true }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <NoFoundData />
            )}

            {showCreateAttendeeModal && selectedEvent && (
                <CreateAttendeeModal
                    showModal={showCreateAttendeeModal}
                    setShowModal={setShowCreateAttendeeModal}
                    submit={submitCreateAttendee}
                    selectedEventId={selectedEvent}
                    event={event}
                />
            )}
            {showUpdateAttendeeModal && selectedAttendee && (
                <UpdateAttendeeModal
                    showModal={showUpdateAttendeeModal}
                    setShowModal={setShowUpdateAttendeeModal}
                    submit={submitUpdateAttendee}
                    selectedAttendee={selectedAttendee}
                    event={event}
                />
            )}
            {showDeleteAttendeeModal && selectedAttendee && (
                <DeleteAttendeeModal
                    showModal={showDeleteAttendeeModal}
                    setShowModal={setShowDeleteAttendeeModal}
                    submit={submitDeleteAttendee}
                    selectedAttendee={selectedAttendee}
                />
            )}
            {showDetailsAttendeeModal && selectedAttendee && (
                <DetailsAttendeeModal
                    showModal={showDetailsAttendeeModal}
                    setShowModal={setShowDetailsAttendeeModal}
                    selectedAttendee={selectedAttendee}
                />
            )}
            {showUploadFileModal && (
                <FileUploadModal
                    showModal={showUploadFileModal}
                    setShowModal={setShowUploadFileModal}
                    submit={submitCreateAttendees}
                />
            )}
        </Box>
    );
};

export default Attendees;