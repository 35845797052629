import React from 'react'
import {
  Box,
  Typography,
  Modal,
  Stack,
  useTheme,
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './DeleteAttendeeModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import { AttendeeType } from '../../types'
import { responsiveStyles } from 'src/themes/breakpoints'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: () => void
  selectedAttendee: AttendeeType
}

const DeleteAttendeeModal: React.FC<Props> = ({ selectedAttendee, showModal, setShowModal, submit }) => {
  const theme = useTheme();
  const responsive = responsiveStyles(theme);
  
  const handleClose = (): void => { setShowModal(false) }
  const handleSubmit = (): void => {
    submit()
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={{
        ...styles.viewModal,
        ...responsive.modalWidth,
        maxHeight: '90vh',
        overflowY: 'auto',
      }}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Deletar Participante</Header>
        </Box>

        <Box sx={{
          ...styles.innerContainer,
          ...responsive.containerPadding,
        }}>
          <Typography sx={responsive.fontSize}>
            Você tem certeza que deseja deletar o participante "{selectedAttendee.firstName}"?
          </Typography>
          <Stack
            sx={{ 
              paddingTop: 5,
              ...responsive.buttonStack,
              width: '100%',
              justifyContent: {
                xs: 'center',
                sm: 'flex-end',
              },
              '& > button': {
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              },
            }}
          >
            <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
            <Button onClick={handleSubmit}>Deletar</Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteAttendeeModal
