import React, { type ChangeEvent, type FC, useState } from "react";
import TextField from "@mui/material/TextField";

interface InputFieldProps {
  label: string;
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  type?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  autoComplete?: string
}

interface SelectInputFieldProps {
  label: string;
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  select?: number;
  children: any;
  disabled?: boolean
}

const CustomInputField: FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  required,
  type,
  placeholder,
  maxLength,
  disabled,
  error,
  helperText,
  autoComplete,
}) => {
  const [requiredEerror, setRequiredEerror] = useState(false);

  const handleBlur = (): void => {
    try {
      if (required && (value === null || value.trim() === "")) {
        setRequiredEerror(true);
      } else {
        setRequiredEerror(false);
      }
    } catch (error) {
      setRequiredEerror(true);
    }
  };

  return (
    <TextField
      error={requiredEerror || error}
      helperText={helperText || ""}
      label={label}
      fullWidth
      size="small"
      required={required || false}
      type={type || "text"}
      value={value}
      placeholder={placeholder || ''}
      onChange={onChange}
      onBlur={handleBlur}
      disabled={disabled || false}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        maxLength: maxLength ? maxLength : undefined,
        ...(type === 'number' ? { min: 0 } : {})
      }}
      autoComplete={autoComplete}
    />
  );
};

const CustomSelectInputField: FC<SelectInputFieldProps> = ({
  children,
  label,
  value,
  onChange,
  required,
  disabled
}) => {
  const [error, setError] = useState(false);

  const handleBlur = (): void => {
    try {
      if (required && (value === null || value.trim() === "")) {
        setError(true);
      } else {
        setError(false);
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <TextField
      error={error}
      helperText={error ? "Campo obrigatório" : ""}
      label={label}
      fullWidth
      size="small"
      required={required || false}
      value={value}
      disabled={disabled || false}
      select
      onChange={onChange}
      onBlur={handleBlur}
      InputLabelProps={{
        shrink: true,
      }}
    // sx={{ margin: `0 !important` }}
    >
      {children}
    </TextField>
  );
};

export { CustomInputField, CustomSelectInputField };
