import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, IconButton, Typography, useTheme, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Props } from './types';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    paddingTop: theme.spacing(2),
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%',
    },
    maxHeight: '90vh',
  }
}));

const Dropzone = styled('div')(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
}));

const FileUploadModal: React.FC<Props> = ({ showModal, setShowModal, submit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDrop = useCallback((e: any) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      const file = files[0];
      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setSelectedFile(file);
      } else {
        alert("Please select an XLSX file.");
      }
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const onFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setSelectedFile(file);
    } else {
      alert("Please select an XLSX file.");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      submit(selectedFile)
    }
  };

  const handleDownloadExample = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + '/template-convidados.xlsx'; // Caminho do arquivo na pasta public
    link.download = 'template-convidados.xlsx';
    link.click();
  };

  return (
    <StyledDialog open={showModal} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: { xs: 2, sm: 3 } }}>
        <Typography sx={{ fontSize: { xs: '16px', sm: '18px' } }}>
          Carregar Arquivo XLSX
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ 
        padding: { xs: 2, sm: 3, md: 4 },
      }}>
        <input
          accept=".xlsx"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={onFileChange}
        />
        <label htmlFor="raised-button-file" style={{ width: '100%', display: 'block' }}>
          <Dropzone onDragOver={onDragOver} onDrop={onDrop}>
            <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }}>
              Arraste e solte o arquivo XLSX aqui, ou clique para selecionar.
            </Typography>
          </Dropzone>
        </label>
        {selectedFile && (
          <Typography variant="subtitle1" sx={{ 
            marginTop: 2, 
            fontSize: { xs: '12px', sm: '14px' } 
          }}>
            {selectedFile.name}
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ 
        flexDirection: { xs: 'column', sm: 'row' },
        gap: { xs: 1, sm: 2 },
        padding: { xs: 2, sm: 2 },
        alignItems: 'stretch'
      }}>
        <Button 
          color="primary" 
          variant="outlined" 
          onClick={handleDownloadExample}
          fullWidth={isMobile}
          sx={{ fontSize: { xs: '12px', sm: '14px' } }}
        >
          Baixar Modelo
        </Button>
        <Button 
          onClick={handleUpload} 
          color="primary" 
          variant="contained"
          fullWidth={isMobile}
          sx={{ fontSize: { xs: '12px', sm: '14px' } }}
        >
          Salvar
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default FileUploadModal;
