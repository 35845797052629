import { useCallback, useContext, useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import Header from "src/components/Header";
import Container from "src/components/Container";
import InnerContainer from "src/components/InnerContainer";
import Sidebar from "src/components/Sidebar";
import { styles } from "./style";
import { AccountModel } from "src/data/models";
import AxiosClient from "src/clients/axios.client";
import ContextWrapper from "src/context/context/wrapper";
import ChangeAvatarModal from "./ChangeAvatar/ChangeAvatar";
import { validatePassword } from "src/utils/validatePassword";
import { CustomInputField } from "src/components/TextField";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import { unformatPhone } from "src/utils/unformatPhone";
import { validatePhoneNumber } from "src/utils/validatePhoneNumber";

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

type PasswordValidationKeys = keyof ReturnType<typeof validatePassword>;
type PasswordRule = {
    key: PasswordValidationKeys;
    text: string;
};

const MyAccount: React.FC = () => {
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const [tabValue, setTabValue] = useState(0);
    const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
    const [password, setPassword] = useState({
        prevPassword: "",
        prevPasswordError: "",
        newPassword: "",
        newPasswordError: "",
        confirmNewPassword: "",
        confirmNewPasswordError: "",
        mainError: "",
        isFormInvalid: false,
    });
    const [editAccount, setEditAccount] = useState(false);
    const [account, setAccount] = useState<Omit<AccountModel, "password">>({
        id: "",
        avatar: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        accessToken: "",
        isConfirmed: false,
        registeredAt: new Date(),
        role: "",
        isActive: true,
    });
    const [initialAccount, setInitialAccount] = useState({ ...account });

    const handleTabChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };
    const handleEdit = (val: boolean) => setEditAccount(!editAccount);

    const handlePhone = (val: string) => {
        const formattedPhoneNumber = formatPhoneNumber(val);
        handleChange(formattedPhoneNumber, 'phone')
    }

    const handleChange = (val: string, prop: string) => {
        setAccount((prevState) => ({
            ...prevState,
            [prop]: val,
        }));
    };

    const handlePassword = (val: string, prop: string) => {
        setPassword((prevState) => ({
            ...prevState,
            [prop]: val,
        }));
    };

    const getAccount = async () => {
        try {
            setLoading(true);
            const id = localStorage.getItem("id");
            console.log('ID: ', id)
            if (!id) return;
            const httpResponse = await AxiosClient.getAccount(id);
            if (httpResponse.status === 200) {
                const acc = {
                    ...httpResponse.data,
                    ...(httpResponse.data.phone ? {phone: formatPhoneNumber(httpResponse.data.phone.slice(3))} : {})
                }
                setAccount(acc);
                setInitialAccount(acc);
            }
        } catch (error) {
            enqueueSnackbar("Erro ao buscar conta de usuário", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            const id = localStorage.getItem("id");
            if (!id) return;
            if(account.phone && !validatePhoneNumber(account.phone)) {
                enqueueSnackbar(
                    "Número de telefone inválido.",
                    { variant: "error" }
                );
                return;
            }
            setLoading(true);
            const body = {
                ...account,
                ...(account.phone ? {phone:  `+55${unformatPhone(account.phone)}`} : {}) 
            }
            const httpResponse = await AxiosClient.updateAccount(body);
            localStorage.setItem("firstName", httpResponse.data.firstName);
            enqueueSnackbar("Usuário atualizado com sucesso.", {
                variant: "success",
            });
            setAccount(httpResponse.data);
            setEditAccount(false);
            return;

        } catch (error: any) {
            setLoading(false);
            enqueueSnackbar(error.response.data.error || "Falha ao atualizar usuário.", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleSaveAvatar = async (avatar: File | null) => {
        try {
          if (!avatar) {
            console.log("Nenhuma imagem selecionada");
            return;
          }
      
          const formData = new FormData();
          formData.append("avatar", avatar); // Envia o arquivo bruto
      
          setLoading(true);
          const httpResponse = await AxiosClient.updateAvatarAccount(account.id, formData);
      
          if (httpResponse.status === 200) {
            enqueueSnackbar("Avatar atualizado com sucesso.", {
              variant: "success",
            });
            localStorage.setItem("avatar", httpResponse.data.avatar);
            setAccount(httpResponse.data);
            setShowChangeAvatarModal(false);
            return;
          }
          enqueueSnackbar("Falha ao atualizar usuário.", { variant: "error" });
        } catch (error) {
          console.error("Erro ao atualizar avatar:", error);
          enqueueSnackbar("Falha ao atualizar usuário.", { variant: "error" });
        } finally {
          setLoading(false);
        }
      };

    const handleDeleteAvatar = async () => {
        try {
            setLoading(true);
            const httpResponse = await AxiosClient.deleteAvatarAccount(account.id);
            setLoading(false);

            if (httpResponse.status === 200) {
                enqueueSnackbar("Avatar atualizado com sucesso.", {
                    variant: "success",
                });
                setAccount(httpResponse.data);
                setShowChangeAvatarModal(false);
                return;
            }
            enqueueSnackbar("Falha ao atualizar usuário.", { variant: "error" });
        } catch (error) {
            setLoading(false);
            enqueueSnackbar("Falha ao atualizar usuário.", { variant: "error" });
        }
    };

    useEffect(() => {
        setPassword((prev) => ({
            ...prev,
            newPasswordError: "",
            confirmNewPasswordError: "",
            prevPasswordError: "",
            mainError: "",
        }));
    }, [
        password.newPassword,
        password.confirmNewPassword,
        password.prevPassword,
    ]);

    const validatePasswordRules = (text: string) => {
        const rules: PasswordRule[] = [
            { key: "minLength", text: "Pelo menos 8 caracteres" },
            { key: "hasUpperCase", text: "Pelo menos uma letra maiúscula" },
            { key: "hasLowerCase", text: "Pelo menos uma letra minúscula" },
            { key: "hasNumbers", text: "Pelo menos um número" },
            { key: "hasSpecialChar", text: "Pelo menos um caractere especial" },
        ];
        const passValidation = validatePassword(text);
        const isPasswordInvalid = rules.find((rule) => !passValidation[rule.key]);
        return isPasswordInvalid;
    };
    const passwordValidation = (): boolean => {
        let isFormInvalid = false;
        if (!password.newPassword) {
            setPassword({ ...password, newPasswordError: "O campo está vazio." });
            isFormInvalid = true;
            return isFormInvalid;
        }
        if (!password.prevPassword) {
            setPassword({ ...password, prevPasswordError: "O campo está vazio." });
            isFormInvalid = true;
            return isFormInvalid;
        }
        const newPassIsValid = validatePasswordRules(password.newPassword);
        if (newPassIsValid) {
            setPassword({ ...password, newPasswordError: newPassIsValid.text });
            isFormInvalid = true;
            return isFormInvalid;
        }
        const prevPassIsValid = validatePasswordRules(password.prevPassword);
        if (prevPassIsValid) {
            setPassword({ ...password, prevPasswordError: prevPassIsValid.text });
            isFormInvalid = true;
            return isFormInvalid;
        }
        if (password.newPassword !== password.confirmNewPassword) {
            setPassword({
                ...password,
                newPasswordError:
                    "Os campos Nova Senha e Confirmar Senha não conferem.",
                confirmNewPasswordError:
                    "Os campos Nova Senha e Confirmar Senha não conferem.",
            });
            isFormInvalid = true;
            return isFormInvalid;
        }
        return isFormInvalid;
    };

    const handleSubmitUpdatePassword = async () => {
        try {
            if (passwordValidation()) {
                return;
            }
            setLoading(true);
            const data = {
                newPassword: password.newPassword,
                prevPassword: password.prevPassword,
                id: account.id,
            };
            const httpResponse = await AxiosClient.updatePassword(data);
            setLoading(false);

            enqueueSnackbar("Senha atualizada com sucesso.", { variant: "success" });
            setPassword((prev) => ({
                ...prev,
                prevPassword: "",
                prevPasswordError: "",
                newPassword: "",
                newPasswordError: "",
                confirmNewPassword: "",
                confirmNewPasswordError: "",
                mainError: "",
                isFormInvalid: false,
            }));
            setAccount(httpResponse.data);
            setShowChangeAvatarModal(false);
            return;
        } catch (error: any) {
            console.log("ERROR:: ", error.response.data.error);
            setPassword((prev) => ({
                ...prev,
                mainError: error.response.data.error || error.response.data.error || "",
            }));
            setLoading(false);
            enqueueSnackbar("Falha ao atualizar usuário.", { variant: "error" });
        }
    };

    const handleShowAvatarModal = () => {
        setShowChangeAvatarModal(true);
    };

    const handleCancel = () => {
        setEditAccount(false);
        setAccount(initialAccount);
    };

    useEffect(() => {
        getAccount();
    }, []);

    return (
        <Sidebar>
            <Container>
                <InnerContainer>
                    <Header>Minha Conta</Header>
                </InnerContainer>
                <InnerContainer>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="configurações da conta"
                    >
                        <Tab label="Dados do Usuário" />
                        <Tab label="Senha" />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Box sx={styles.container}>
                            <Tooltip title="Editar">
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    sx={styles.editAccount}
                                    onClick={() => handleEdit(false)}
                                >
                                    <EditIcon sx={styles.editIcon} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" sx={styles.title}>
                                Informações do Usuário
                            </Typography>
                            <Grid container spacing={0} alignItems="stretch">
                                {/* Lado Esquerdo: Avatar e Botões */}
                                <Grid item xs={4} md={2}>
                                    <Box sx={styles.avatarContainer}>
                                        <Avatar
                                            src={account?.avatar || "default-avatar.jpg"}
                                            alt={account?.firstName}
                                            sx={{ width: 100, height: 100, mx: "auto" }}
                                        />
                                        <div style={{ marginTop: "10px" }}>
                                            <Button
                                                onClick={() => handleShowAvatarModal()}
                                                color="primary"
                                                sx={{
                                                    textTransform: "none",
                                                    padding: 0,
                                                    minWidth: "auto",
                                                }}
                                            >
                                                Trocar
                                            </Button>
                                            {" | "}
                                            <Button
                                                color="secondary"
                                                sx={{
                                                    textTransform: "none",
                                                    padding: 0,
                                                    minWidth: "auto",
                                                }}
                                                onClick={() => handleDeleteAvatar()}
                                            >
                                                Remover
                                            </Button>
                                        </div>
                                    </Box>
                                </Grid>
                                {/* Lado Direito: Campos de Informação */}
                                <Grid item xs={8} md={10}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="flex-start"
                                        paddingBottom={2}
                                        marginLeft={5}
                                    >
                                        <CustomInputField
                                            label="Nome"
                                            placeholder="Digite aqui o nome..."
                                            value={account?.firstName || ""}
                                            disabled={!editAccount}
                                            onChange={(e) =>
                                                handleChange(e.target.value, "firstName")
                                            }
                                        />
                                        <CustomInputField
                                            label="Sobrenome"
                                            placeholder="Digite aqui o sobrenome..."
                                            value={account?.lastName || ""}
                                            disabled={!editAccount}
                                            onChange={(e) => handleChange(e.target.value, "lastName")}
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="flex-start"
                                        paddingBottom={2}
                                        marginLeft={5}
                                    >
                                        <CustomInputField
                                            label="Email"
                                            value={account?.email || ""}
                                            disabled={!editAccount}
                                            onChange={(e) => handleChange(e.target.value, "email")}
                                        />
                                        <CustomInputField
                                            label="Telefone"
                                            placeholder="Digite aqui o telefone..."
                                            value={account?.phone || ""}
                                            disabled={!editAccount}
                                            onChange={(e) => handlePhone(e.target.value)}
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        justifyContent="flex-start"
                                        marginLeft={5}
                                    >
                                        <CustomInputField
                                            label="Endereço"
                                            placeholder="Digite aqui o endereço..."
                                            value={account?.address || ""}
                                            disabled={!editAccount}
                                            onChange={(e) => handleChange(e.target.value, "address")}
                                        />
                                    </Stack>
                                    {editAccount && (
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            justifyContent="flex-start"
                                            marginLeft={5}
                                        >
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                style={styles.editUserButton}
                                                onClick={() => handleCancel()}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                style={styles.editUserButton}
                                                onClick={() => handleSubmit()}
                                            >
                                                Salvar Alterações
                                            </Button>
                                        </Stack>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Typography variant="h6" sx={styles.title}>
                            Trocar Senha
                        </Typography>
                        <Box sx={{ ...styles.row, marginBottom: 3, width: '30%' }}>
                            <CustomInputField
                                required
                                label="Nova Senha"
                                placeholder="Digite aqui a nova senha..."
                                type={password.newPassword ? "text" : "password"}
                                value={password.newPassword}
                                error={!!password.newPasswordError}
                                helperText={password.newPasswordError}
                                onChange={(e) => handlePassword(e.target.value, "newPassword")}
                            />
                        </Box>
                        <Box sx={{ ...styles.row, marginBottom: 3, width: '30%' }}>
                            <CustomInputField
                                required
                                label="Confirmar Nova Senha"
                                placeholder="Digite aqui a nova senha..."
                                type={password.confirmNewPassword ? "text" : "password"}
                                value={password.confirmNewPassword}
                                error={!!password.confirmNewPasswordError}
                                helperText={password.confirmNewPasswordError}
                                onChange={(e) =>
                                    handlePassword(e.target.value, "confirmNewPassword")
                                }
                            />
                        </Box>
                        <Box sx={{ ...styles.row, marginBottom: 3, width: '30%' }}>
                            <CustomInputField
                                required
                                label="Senha Antiga"
                                placeholder="Digite aqui a senha antiga..."
                                autoComplete="current-password"
                                value={password.prevPassword}
                                error={!!password.prevPasswordError}
                                helperText={password.prevPasswordError}
                                type={password.prevPassword ? "text" : "password"}
                                onChange={(e) => handlePassword(e.target.value, "prevPassword")}
                            />
                        </Box>
                        {password.mainError && (
                            <Typography sx={styles.error}>{password.mainError}</Typography>
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={styles.submit}
                            onClick={() => handleSubmitUpdatePassword()}
                        >
                            Salvar Alterações
                        </Button>
                    </TabPanel>
                </InnerContainer>
            </Container>
            {showChangeAvatarModal && (
                <ChangeAvatarModal
                    showModal={showChangeAvatarModal}
                    setShowModal={setShowChangeAvatarModal}
                    handleSaveAvatar={handleSaveAvatar}
                />
            )}
        </Sidebar>
    );
};

export default MyAccount;
