import { Theme } from '@mui/material/styles';
import { ResponsiveStyleValue } from '@mui/system';

// Breakpoints padrão do MUI (em pixels)
export const breakpoints = {
  xs: 0,    // Extra-small: 0px+
  sm: 600,  // Small: 600px+
  md: 900,  // Medium: 900px+
  lg: 1200, // Large: 1200px+
  xl: 1536, // Extra-large: 1536px+
};

// Função para aplicar estilos responsivos com base no tema
export const responsiveStyles = (theme: Theme) => ({
  modalWidth: {
    width: {
      xs: '95%',  // Quase tela cheia em mobile
      sm: '85%',  // Um pouco menor em tablets pequenos
      md: '70%',  // Moderado em tablets grandes
      lg: '50%',  // Metade da tela em desktop
      xl: '40%',  // Ainda menor em telas muito grandes
    },
  },
  fieldWidth: {
    width: {
      xs: '100%', // Campos ocupam toda a largura em mobile
      sm: '48%',  // Dois campos por linha em tablets
      md: '48%',  // Mantém em telas médias
      lg: '48%',  // Mantém em desktop
      xl: '48%',  // Mantém em telas grandes
    },
    minWidth: {
      xs: "200px", // Tamanho mínimo em mobile para garantir usabilidade
      sm: "250px", // Aumenta ligeiramente em sm+
    },
  },
  containerPadding: {
    p: {
      xs: 2, // Menos padding em mobile
      sm: 3, // Aumenta um pouco em tablets
      md: 4, // Padrão em desktop
    },
  },
  rowDirection: {
    flexDirection: {
      xs: 'column', // Empilha em mobile
      sm: 'row',    // Lado a lado em tablets+
    } as ResponsiveStyleValue<"column" | "row" | "row-reverse" | "column-reverse">,
    gap: {
      xs: 1, // Menor espaçamento em mobile
      sm: 2, // Espaçamento padrão em telas maiores
    },
  },
  buttonStack: {
    flexDirection: "row" as "column" | "row" | "row-reverse" | "column-reverse", // Sempre em row, sem mudar para column
    flexWrap: "wrap", // Permite que os botões quebrem para a próxima linha se necessário
    gap: {
      xs: 0.5, // Reduz o espaçamento em telas pequenas
      sm: 1,   // Espaçamento maior em sm+
    },
    justifyContent: "flex-end", // Alinha os botões à direita
  },
  fontSize: {
    fontSize: {
      xs: '12px', // Menor em mobile
      sm: '14px', // Padrão em tablets+
    },
  },
});

export default responsiveStyles;