import React from 'react'
import { Button } from '@mui/material'
import styles from './styles'

interface Props {
  onClick: () => void
  isDisable?: boolean
  bgColor?: string
  variant?: 'text' | 'contained' | 'outlined'
  children: React.ReactNode
  style?: any
}

const CustomButton: React.FC<Props> = ({ onClick, isDisable, children, bgColor, variant, style={} }) => {
  const pressed = (): void => { onClick() }
  return (
    <Button
      variant={variant || "contained"}
      sx={[
        styles.button,
        variant === 'outlined' && { background: 'white' },
        isDisable ? { background: 'white' } : null,
        style
      ]}
      disabled={isDisable}
      onClick={() => { pressed() }}
    >
      {children}
    </Button>
  )
}

export default CustomButton
