import {
    Box,
    Button,
    IconButton,
    Stack,
    Tooltip,
    TooltipProps,
    Typography,
    styled,
    tooltipClasses,
} from "@mui/material";
import { format } from "date-fns";

import InnerContainer from "src/components/InnerContainer";

import {
    EditCalendar as EditCalendarIcon,
    RemoveCircle as RemoveCircleIcon,
    Info as InfoIcon,
    CalendarMonth as CalendarMonthIcon,
    PeopleOutline as PeopleOutlineIcon,
    GroupAdd as GroupAddIcon,
    ManageHistory as ManageHistoryIcon,
} from "@mui/icons-material";
import React from "react";
import { styles } from "./styles";
import {
    CoordinatorModel,
    EventModel,
    TempCoordinatorsModel,
} from "src/data/models";

const EVENT_TYPES = [
    { value: "wedding", label: "Casamento" },
    { value: "birthday_party", label: "Aniversário" },
    { value: "christening_blessing", label: "Batizado" },
    { value: "concert", label: "Show" },
    { value: "conference", label: "Conferência" },
    { value: "seminar", label: "Seminário" },
    { value: "exhibition", label: "Exibição" },
    { value: "fair", label: "Feira" },
    { value: "festival", label: "Festival" },
    { value: "workshop", label: "Workshop" },
    { value: "product_launch", label: "Lançamento" },
    { value: "award_ceremony", label: "Cerimônia de Premiação" },
    { value: "themed_party", label: "Festa Temática" },
    { value: "charity_auction", label: "Leilão" },
    { value: "theater", label: "Teatro" },
];

type Props = {
    event: EventModel;
    coordinators: { rows: CoordinatorModel[]; count: number };
    tempCoordinators: { rows: TempCoordinatorsModel[]; count: number };
    userEventAssociation: CoordinatorModel | undefined;
    handleShowUpdateModal: (val: boolean) => void;
    handleRemoveTemporaryCoordinator: (val: any) => void;
    handleRemoveCoordinator: (val: any) => void;
    handleShowAddCoord: (val: boolean) => void;
    handleShowSchedule: (val: boolean) => void;
};
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#999",
        color: "#fff", // Cor do texto
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        padding: "12px",
    },
}));

export const Details: React.FC<Props> = ({
    event,
    coordinators,
    tempCoordinators,
    userEventAssociation,
    handleShowUpdateModal,
    handleRemoveTemporaryCoordinator,
    handleRemoveCoordinator,
    handleShowAddCoord,
    handleShowSchedule,
}) => {
    const handleEventType = (eventType: string) => {
        const evt = EVENT_TYPES.filter((type) => type.value === eventType);
        if (evt.length) return evt[0].label;
        return "";
    };
    return (
        <Box>
            <InnerContainer>
                <Stack direction="row" spacing={0}>
                    <Stack sx={{ paddingRight: 3, paddingLeft: 5 }}>
                        <CalendarMonthIcon color="primary" sx={styles.icons} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Typography sx={styles.details}>Detalhes</Typography>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Nome: </Typography>
                            <Typography sx={styles.val}>{event.title}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Início: </Typography>
                            <Typography sx={styles.val}>
                                {format(new Date(event.startAt), "dd/MM/yyyy HH:mm")}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Término: </Typography>
                            <Typography sx={styles.val}>
                                {format(new Date(event.endAt), "dd/MM/yyyy HH:mm")}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Local: </Typography>
                            <Typography sx={styles.val}>{event.location}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Exigir Confirmação? </Typography>
                            <Typography sx={styles.val}>
                                {event.isRSVPRequired ? "Sim" : "Não"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>
                                Confirmar presença até::{" "}
                            </Typography>
                            <Typography sx={styles.val}>
                                {event?.registrationDeadline ? format(new Date(event.registrationDeadline), "dd/MM/yyyy HH:mm") : ''}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Tipo de evento: </Typography>
                            <Typography sx={styles.val}>
                                {handleEventType(event.eventType)}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Ingresso obrigatório? </Typography>
                            <Typography sx={styles.val}>
                                {event.isTicketed ? "Sim" : "Não"}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>N. de convites: </Typography>
                            <Typography sx={styles.val}>
                                {event.isTicketed ? event.maxTicketQuantity : "-"}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            display: event.isCancelled ? "none" : "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            paddingRight: 3,
                        }}
                    >
                        <Button
                            onClick={() => handleShowUpdateModal(true)}
                            variant="outlined"
                            startIcon={<EditCalendarIcon />}
                        >
                            Editar
                        </Button>
                    </Box>
                </Stack>
            </InnerContainer>
            <InnerContainer>
                <Stack direction="row" spacing={0}>
                    <Stack sx={{ paddingRight: 3, paddingLeft: 5 }}>
                        <PeopleOutlineIcon color="primary" sx={styles.icons} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Typography sx={styles.details}>Coordenador</Typography>
                        {coordinators.count === 0 && tempCoordinators.count === 0 && (
                            <Typography sx={styles.error}>
                                Falha ao buscar coordenadores.
                            </Typography>
                        )}
                        {coordinators.rows.map((item, index) => (
                            <Stack
                                direction="row"
                                spacing={0}
                                key={index}
                                alignItems="center"
                            >
                                <Typography
                                    sx={
                                        item.isConfirmed
                                            ? styles.val
                                            : { ...styles.val, color: "#aaa" }
                                    }
                                >
                                    {item.user.email}
                                </Typography>
                                {item.isOwner ? (
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit" sx={{ fontSize: 15 }}>
                                                    Administrador
                                                </Typography>
                                                <b>{"Nome:"}</b> {item.user.firstName}
                                                <br></br>
                                                <b>{"Aceite:"}</b>{" "}
                                                {item.isConfirmed ? "Aprovado" : "Pendente"}
                                                <br></br>
                                            </React.Fragment>
                                        }
                                    >
                                        <InfoIcon color="info" sx={{ marginLeft: "6px" }} />
                                    </HtmlTooltip>
                                ) : (
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                {userEventAssociation?.isOwner && (
                                                    <Typography color="inherit" sx={{ fontSize: 15 }}>
                                                        Remover Coordenador
                                                    </Typography>
                                                )}
                                                <b>{"Nome:"}</b> {item.user.firstName}
                                                <br></br>
                                                <b>{"Aceite:"}</b>{" "}
                                                {item.isConfirmed ? "Aprovado" : "Pendente"}
                                                <br></br>
                                            </React.Fragment>
                                        }
                                    >
                                        {userEventAssociation?.isOwner ? (
                                            <IconButton
                                                aria-label="remove"
                                                onClick={() => handleRemoveCoordinator(item)}
                                            >
                                                <RemoveCircleIcon color="error" />
                                            </IconButton>
                                        ) : (
                                            <InfoIcon color="info" sx={{ marginLeft: "6px" }} />
                                        )}
                                    </HtmlTooltip>
                                )}
                            </Stack>
                        ))}
                        {tempCoordinators.rows.map((item, index) => (
                            <Stack
                                direction="row"
                                spacing={0}
                                key={index}
                                alignItems="center"
                            >
                                <Typography sx={{ ...styles.val, color: "#aaa" }}>
                                    {item.email}
                                </Typography>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography color="inherit" sx={{ fontSize: 15 }}>
                                                Remover Coordenador
                                            </Typography>
                                            <b>{"Nome:"}</b> {"-"}
                                            <br></br>
                                            <br></br>
                                            <b>{"Usuário não registrado."}</b>
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton
                                        aria-label="remove"
                                        onClick={() => handleRemoveTemporaryCoordinator(item)}
                                    >
                                        <RemoveCircleIcon color="error" />
                                    </IconButton>
                                </HtmlTooltip>
                            </Stack>
                        ))}
                    </Stack>
                    {userEventAssociation?.isOwner && (
                        <Box
                            sx={{
                                display: event.isCancelled ? "none" : "flex",
                                flex: 1,
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                paddingRight: 3,
                            }}
                        >
                            <Button
                                onClick={() => handleShowAddCoord(true)}
                                variant="outlined"
                                startIcon={<GroupAddIcon />}
                            >
                                Coordenador
                            </Button>
                        </Box>
                    )}
                </Stack>
            </InnerContainer>
            <InnerContainer>
                <Stack direction="row" spacing={0}>
                    <Stack sx={{ paddingRight: 3, paddingLeft: 5 }}>
                        <ManageHistoryIcon color="primary" sx={styles.icons} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Typography sx={styles.details}>Programação</Typography>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Patrocinadores: </Typography>
                            <Stack direction="column" spacing={0}>
                                {event?.sponsors?.length
                                    ? event.sponsors.map((sponsor, index) => (
                                        <Typography sx={styles.val} key={index}>
                                            {sponsor || "-"}
                                        </Typography>
                                    ))
                                    : "-"}
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>
                                Cantores/Apresentadores:{" "}
                            </Typography>
                            <Stack direction="column" spacing={0}>
                                {event?.speakers?.length
                                    ? event.speakers.map((speaker, index) => (
                                        <Typography sx={styles.val} key={index}>
                                            {speaker || "-"}
                                        </Typography>
                                    ))
                                    : "-"}
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <Typography sx={styles.label}>Agenda: </Typography>
                            <Stack direction="column" spacing={0}>
                                {event.agenda.length
                                    ? event.agenda.map((session, index) => {
                                        try {
                                            const exhibition = JSON.parse(session);
                                            return (
                                                <Typography sx={styles.val} key={index}>
                                                    {exhibition.time
                                                        ? format(
                                                            new Date(exhibition.time),
                                                            "dd/MM/yyyy HH:mm:ss"
                                                        )
                                                        : ""}{" "}
                                                    - {exhibition.activity}
                                                </Typography>
                                            );
                                        } catch (error) {
                                            return null;
                                        }
                                    })
                                    : "-"}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            display: event.isCancelled ? "none" : "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            paddingRight: 3,
                        }}
                    >
                        <Button
                            onClick={() => handleShowSchedule(true)}
                            variant="outlined"
                            startIcon={<EditCalendarIcon />}
                        >
                            Editar
                        </Button>
                    </Box>
                </Stack>
            </InnerContainer>
        </Box>
    );
};
