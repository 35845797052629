import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    ChangeEvent,
    useMemo,
} from "react";
import {
    Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableFooter,
    TablePagination,
    Button as MUIButton,
    Tooltip,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    TextField,
    MenuItem,
    IconButton,
    InputBase,
    Divider,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { format } from "date-fns";

import {
    Send as SendIcon,
    Refresh as RefreshIcon,
    Block as BlockIcon,
    TaskAlt as TaskAltIcon,
    Unpublished as UnpublishedIcon,
    Download as DownloadIcon,
    ConfirmationNumber as ConfirmationNumberIcon,
    Add as AddIcon,
    FilterListOff as FilterListOffIcon,
    CancelScheduleSend as CancelScheduleSendIcon,
    Email as EmailIcon,
    WhatsApp as WhatsAppIcon,
    RecordVoiceOver as RecordVoiceOverIcon,
    ThumbUpAlt as ThumbUpAltIcon,
    Search as SearchIcon,
    Stroller as StrollerIcon,
    Star as StarIcon,
    PeopleOutline as PeopleOutlineIcon,
    PeopleAlt as PeopleAltIcon,
} from "@mui/icons-material";
import CreateTicketModal from "./components/CreateTicketModal/CreateTicketModal";
import CancelTicketModal from "./components/CancelTicketModal/CancelTicketModal";
import ConfirmTicketSubmissionModal from "./components/ConfirmTicketSubmissionModal/ConfirmTicketSubmissionModal";
import NoFoundData from "src/components/NoFoundData";
import { AttendeeType } from "./components/CreateTicketModal/types";

import AxiosClient from "src/clients/axios.client";
import styles from "./styles";
import { TicketType } from "./types";
import {
    CancelTicketType,
    ConfirmTicketSubmissionType,
    CreateTicket,
    CreateTicketFoAllAttendees,
} from "src/clients/types";
import ContextWrapper from "src/context/context/wrapper";
import { toTitleCase } from "src/utils/toTitleCase";
import { EventModel } from "src/data/models";
import theme from "src/themes/theme";
import ConfirmEntryModal from "./components/ConfirmEntryModal/ConfirmEntryModal";

const actions = [
    { icon: <SendIcon />, name: "Enviar" },
    { icon: <BlockIcon />, name: "Cancelar" },
    { icon: <DownloadIcon />, name: "Baixar" },
    { icon: <ThumbUpAltIcon />, name: "Confirmar Entrada" },
    { icon: <PeopleAltIcon />, name: "Ver Acompanhantes" },
];

const MENU_YES_NO_LIST = [
    { value: "yes", label: "Sim" },
    { value: "no", label: "Não" },
    { value: "", label: "None" },
];

const Tickets: React.FC = () => {
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const { selectedEvent } = useContext(ContextWrapper["selectedEvent"]());
    const [list, setList] = useState<{ rows: TicketType[]; count: number }>({
        rows: [],
        count: 0,
    });
    const [relationList, setRelationList] = useState<TicketType[]>([]);
    const [ticketIsSent, setTicketIsSent] = useState<string>("");
    const [ticketIsCancelled, setTicketIsCancelled] = useState<string>("");
    const [showCreateTicketModal, setShowCreateTicketModal] =
        useState<boolean>(false);
    const [selectedTicket, setSelectTicket] = useState<TicketType | null>(null);
    const [selectedAttendee, setSelectedAttendee] = useState<AttendeeType | null>(
        null
    );
    const [firstName, setFirstName] = useState("");
    const [showTicketSubmissionModal, setShowTicketSubmissionModal] =
        useState<boolean>(false);
    const [showCancelTicketModal, setShowCancelTicketModal] =
        useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [event, setEvent] = useState<EventModel>({
        agenda: [""],
        budget: 0,
        userId: "",
        cancellationReason: "",
        createdAt: "",
        description: "",
        internalNotes: "",
        dressCode: "",
        endAt: new Date(),
        startAt: new Date(),
        eventType: "",
        id: "",
        isCancelled: false,
        isRSVPRequired: true,
        isTicketed: false,
        location: "",
        registrationDeadline: new Date(),
        speakers: [""],
        sponsors: [""],
        theme: "",
        maxTicketQuantity: 0,
        title: "",
        updatedAt: "",
        maxAgeForFreeAdmission: 0,
        isPrivate: false,
        ticketsCreated: 0,
    });
    const [showConfirmEntry, setShowConfirmEntry] = useState(false);
    const [, setShowAttendeeRelationship] = useState(false);

    const getTickets = async () => {
        const params = {
            page: +page,
            pageSize: +rowsPerPage,
            eventId: selectedEvent,
            isCancelled:
                ticketIsCancelled === "" ? undefined : ticketIsCancelled === "yes",
            attendeeId: selectedAttendee ? selectedAttendee.attendeeId : undefined,
            isSent: ticketIsSent === "" ? undefined : ticketIsSent === "yes",
            attendeeFirstName: firstName,
            orderBy: 'sequenceNumber'
        };
        AxiosClient.getTickets(params)
            .then((httpResponse) => {
                const data = httpResponse.data as { rows: TicketType[]; count: number };
                setList(data);
                setLoading(false);
            })
            .catch((error) => {
                enqueueSnackbar("Erro ao buscar convites", { variant: "error" });
                setLoading(false);
            });
    };

    const getEvent = useCallback(async () => {
        await AxiosClient.getEventById({
            eventId: selectedEvent,
            setLoading,
            setEvent,
        });
    }, [selectedEvent, setLoading]);

    const getAttendeeRelations = async (attendeeId: string) => {
        if (!attendeeId) {
            return;
        }
        setLoading(true)
        AxiosClient.getAttendeeRelations(attendeeId).then((resp) => {
            if (!resp.data.count) {
                enqueueSnackbar('O convidado não possui acompanhantes.', { variant: "warning" })
            }
            setRelationList(resp.data.rows)
            setShowAttendeeRelationship(true)
            setLoading(false)
        }).catch(() => {
            enqueueSnackbar("Erro ao buscar tickets", { variant: "error" });
            setRelationList([])
            setLoading(false)
        })
    };

    const handleChangePage = (e: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const submitCreateTicket = async (
        data: CreateTicket | CreateTicketFoAllAttendees,
        createToAll: boolean
    ) => {
        try {
            setLoading(true);
            const params = { ...data, eventId: selectedEvent };
            let httpResponse = null;
            if (createToAll) {
                httpResponse = await AxiosClient.createTicketsForAll(params);
            } else {
                httpResponse = await AxiosClient.createTicketsForSome(params);
            }
            setLoading(false);
            if (httpResponse.status === 200) {
                getTickets();
                setShowCreateTicketModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar(httpResponse.data.error.message, { variant: "error" });
            }
        } catch (error: any) {
            enqueueSnackbar("Error ao criar", { variant: "error" });
            setLoading(false);
        }
    };

    const submitConfirmTicketSubmission = async (
        data: ConfirmTicketSubmissionType
    ) => {
        try {
            if (!selectedTicket) return;
            setLoading(true);
            const httpResponse = await AxiosClient.confirmTicketSubmission(data);
            setLoading(false);
            if (httpResponse.status === 200) {
                getTickets();
                setShowTicketSubmissionModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao confirmar envio.", { variant: "error" });
            }
        } catch (error) {
            setLoading(false);
        }
    };
    const triggerDownload = (blob: any, fileName: any) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Defina o nome do arquivo aqui
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Limpa o URL temporário
    };

    const submitDownloadTicket = async (ticketId: string, ticketCode: string) => {
        setLoading(true);
        const params = { ticketId };
        AxiosClient.downloadTicket(params)
            .then((blob: any) => {
                triggerDownload(blob.data, `${ticketCode}.pdf`);
            })
            .catch((err) =>
                enqueueSnackbar("Falha ao baixar ingresso.", { variant: "error" })
            );
        setLoading(false);
    };

    const submitCancelTicket = async (data: CancelTicketType) => {
        try {
            setLoading(true);
            const httpResponse = await AxiosClient.cancelTicket(data);
            setLoading(false);
            if (httpResponse.status === 200) {
                getTickets();
                setShowCancelTicketModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao cancelar!", { variant: "error" });
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const submitConfirmEntry = async (ticketCode: string) => {
        try {
            setLoading(true);
            const httpResponse = await AxiosClient.scan(ticketCode);
            setLoading(false);
            if (httpResponse.status === 200) {
                getTickets();
                setShowCancelTicketModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao cancelar!", { variant: "error" });
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleCleanFilter = () => {
        setTicketIsCancelled("");
        setTicketIsSent("");
        setSelectedAttendee(null);
        setFirstName("");
    };

    const handleTicketSubmission = (val: any) => setTicketIsSent(val);
    const handleTicketCancelled = (val: any) => setTicketIsCancelled(val);
    const handleShowCreateTicketModal = () => setShowCreateTicketModal(true);
    const handleActions = (actionName: string, ticket: TicketType) => {
        console.log('actionName: ', actionName)
        setSelectTicket(ticket);
        if (actionName === "Enviar") {
            setShowTicketSubmissionModal(true);
        }
        if (actionName === "Cancelar") {
            setShowCancelTicketModal(true);
        }
        if (actionName === "Baixar") {
            submitDownloadTicket(ticket.ticketId, ticket.ticketCode);
        }
        if (actionName === "Confirmar Entrada") {
            setShowConfirmEntry(true);
        }
        if (actionName === "Ver Acompanhantes") {
            getAttendeeRelations(ticket.attendeeId);
        }
        if (actionName === "Ver Mais") {
            // navigate('/events/details');
        }
    };

    const formatDate = (date: string | Date) =>
        format(new Date(date), "dd/MM/yyyy HH:mm:ss");

    const isEventCancelled = useMemo(() => {
        return event?.isCancelled;
    }, [event]);

    const isEventExpired = useMemo(() => {
        const endAt = new Date(event.endAt);
        const currentDate = new Date();
        return endAt < currentDate;
    }, [event]);

    useEffect(() => {
        setSelectedAttendee(null);
        setFirstName("");
    }, [selectedEvent]);

    useEffect(() => {
        if (selectedEvent) {
            getEvent();
        }
    }, [getEvent, selectedEvent]);

    useEffect(() => {
        if (selectedEvent) {
            getTickets();
        }
    }, [page, rowsPerPage, selectedEvent]);

    const CustomTableRow = ({ ticket, type }: { ticket: TicketType, type: 'main' | 'secondary' }) => (
        <TableRow key={ticket.ticketCode}>
            <TableCell>
                {/* {ticket.ticketAttendee?.firstName
                ? toTitleCase(ticket.ticketAttendee?.firstName)
                : "-"} */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        ...(type ===
                            "secondary"
                            ? { paddingLeft: 3 }
                            : {}),
                    }}
                >
                    <Tooltip
                        title={
                            ticket.ticketAttendee?.attendeeType === "guest"
                                ? "Convidado"
                                : "Acompanhante"
                        }
                    >
                        {ticket.ticketAttendee?.attendeeType === "guest" ? (
                            <StarIcon
                                sx={{ fontSize: 18, paddingRight: 0.5 }}
                            />
                        ) : (
                            <PeopleOutlineIcon
                                sx={{ fontSize: 18, paddingRight: 0.5 }}
                            />
                        )}
                    </Tooltip>
                    {ticket.ticketAttendee?.isChild && (
                        <Tooltip title="Criança">
                            {
                                <StrollerIcon
                                    sx={{ fontSize: 18, paddingRight: 0.5 }}
                                />
                            }
                        </Tooltip>
                    )}
                    {ticket.ticketAttendee?.firstName
                        ? toTitleCase(ticket.ticketAttendee?.firstName)
                        : "-"}
                </Box>
            </TableCell>
            <TableCell>{ticket.ticketTypeName}</TableCell>
            <TableCell sx={{ alignSelf: "center" }}>
                {ticket.price}
            </TableCell>
            <TableCell>
                <Tooltip
                    title={
                        ticket.isCancelled
                            ? "Ingresso Cancelado"
                            : "Ingresso Válido"
                    }
                >
                    {ticket.isCancelled ? (
                        <UnpublishedIcon color="error" />
                    ) : (
                        <TaskAltIcon color="success" />
                    )}
                </Tooltip>
            </TableCell>
            <TableCell>
                <Tooltip
                    title={
                        ticket.isSent
                            ? ticket.sendBy === "email"
                                ? "Enviado via email"
                                : ticket.sendBy === "whatsapp"
                                    ? "Enviado via whatsapp"
                                    : "Enviado por outra via"
                            : "Não Enviado"
                    }
                >
                    {ticket.isSent ? (
                        ticket.sendBy === "email" ? (
                            <EmailIcon
                                sx={{ color: theme.palette.grey[500] }}
                            />
                        ) : ticket.sendBy === "whatsapp" ? (
                            <WhatsAppIcon
                                sx={{ color: theme.palette.grey[500] }}
                            />
                        ) : (
                            <RecordVoiceOverIcon
                                sx={{ color: theme.palette.grey[500] }}
                            />
                        )
                    ) : (
                        <CancelScheduleSendIcon color="error" />
                    )}
                </Tooltip>
            </TableCell>

            <TableCell>
                {ticket.price
                    ? ticket.paymentStatus === "paidout"
                        ? "Pago"
                        : ticket.paymentStatus === "pending"
                            ? "Pendente"
                            : ticket.paymentStatus === "cancel"
                                ? "canceled"
                                : "-"
                    : "-"}
            </TableCell>

            <TableCell>
                {ticket.scannedAt ? formatDate(ticket.scannedAt) : "-"}
            </TableCell>
            <TableCell>
                <Box
                    sx={{
                        width: "0px",
                    }}
                >
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        direction="left"
                        sx={styles.speedDial}
                        icon={<SpeedDialIcon />}
                    >
                        {actions.map((action, index) => {
                            if (ticket.isCancelled && action.name === "Enviar") {
                                return null;
                            }
                            if (
                                ticket.isCancelled &&
                                action.name === "Cancelar"
                            ) {
                                return null;
                            }
                            if (
                                ticket.isTicketUsed &&
                                action.name === "Confirmar Entrada"
                            ) {
                                return null;
                            }

                            if (type === 'secondary' && action.name === "Ver Acompanhantes") {
                                return null
                            }
                            return (
                                <SpeedDialAction
                                    key={index}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) =>
                                        handleActions(action.name, ticket)
                                    }
                                />
                            );
                        })}
                    </SpeedDial>
                </Box>
            </TableCell>
        </TableRow>
    )

    return (
        <Box>
            <Stack
                direction="row"
                spacing={0}
                justifyContent="space-between"
                sx={{ marginBottom: 5 }}
            >
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Paper
                        component="form"
                        sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: 350,
                            boxShadow: "none", // Remove a sombra
                            border: "1px solid", // Adiciona uma borda
                            borderColor: "grey.400",
                            height: '34px',
                            borderRadius: '2px'
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            // fetchAttendeeList();
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            onChange={(e) => setFirstName(e?.target.value)}
                            placeholder="Pesquisar participante..."
                            inputProps={{ "aria-label": "pesquisar nome do participante" }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip title="Pesquisar">
                            <IconButton
                                color="primary"
                                sx={{ p: "10px" }}
                                aria-label="directions"
                                onClick={getTickets}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                    {/* <Autocomplete
                        id="attendeeId"
                        sx={{ width: "150px" }}
                        inputValue={firstName} // Valor digitado pelo usuário
                        onInputChange={(event, newInputValue) => {
                            setFirstName(newInputValue); // Atualiza o valor digitado no input
                        }}
                        value={selectedAttendee} // Valor selecionado da lista
                        onChange={handleChangeAttendee} // Lida com a seleção de uma opção
                        options={attendeeList.rows}
                        getOptionLabel={(option) => (option ? option.firstName : "")}
                        isOptionEqualToValue={(option, value) => option.firstName === value.firstName} // Verifica igualdade entre opção e valor selecionado
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Participante"
                                variant="standard"
                                sx={{ marginLeft: "12px" }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {attendeeLoading && (
                                                <CircularProgress color="inherit" size={20} />
                                            )}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li
                                {...props}
                                key={option.attendeeId || Math.random()}
                                ref={
                                    attendeeList.rows.length ===
                                        attendeeList.rows.indexOf(option) + 1
                                        ? lastItemRef
                                        : null
                                }
                            >
                                {option.firstName}
                            </li>
                        )}
                    /> */}
                    <TextField
                        sx={styles.textField}
                        id="ticketIsSent"
                        select
                        label="Enviado"
                        size="small"
                        value={ticketIsSent}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleTicketSubmission(event.target.value);
                        }}
                    >
                        {MENU_YES_NO_LIST.map((option) => (
                            <MenuItem key={Math.random()} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={styles.textField}
                        id="ticketIsCancelled"
                        select
                        label="Cancelado"
                        size="small"
                        value={ticketIsCancelled}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            handleTicketCancelled(event.target.value);
                        }}
                    >
                        {MENU_YES_NO_LIST.map((option) => (
                            <MenuItem key={Math.random()} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    {(ticketIsCancelled || ticketIsSent || firstName) && (
                        <Tooltip title="Limpar Filtro">
                            <MUIButton
                                variant="contained"
                                onClick={() => handleCleanFilter()}
                            >
                                <FilterListOffIcon />
                            </MUIButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Atualizar">
                        <MUIButton variant="contained" onClick={() => getTickets()}>
                            <RefreshIcon />
                        </MUIButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            isEventCancelled
                                ? "Evento Cancelado"
                                : isEventExpired
                                    ? "Evento Expirado"
                                    : "Criar Ingresso"
                        }
                    >
                        <div style={{ display: "flex" }}>
                            <MUIButton
                                variant="contained"
                                onClick={handleShowCreateTicketModal}
                                disabled={isEventCancelled || isEventExpired}
                            >
                                <AddIcon
                                    sx={{ fontSize: 16, position: "absolute", top: 15, left: 8 }}
                                />
                                <ConfirmationNumberIcon />
                            </MUIButton>
                        </div>
                    </Tooltip>
                </Stack>
            </Stack>
            {list.count ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Participante</TableCell>
                                    <TableCell>Ingresso</TableCell>
                                    <TableCell>Valor (R$)</TableCell>
                                    <TableCell>Válido</TableCell>
                                    <TableCell>Enviado</TableCell>
                                    <TableCell>Pagamento</TableCell>
                                    <TableCell>Usado em</TableCell>
                                    <TableCell>Mais</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.rows.map((ticket) => (
                                    <>
                                        <CustomTableRow key={ticket.ticketCode} ticket={ticket} type="main" />
                                        {selectedTicket?.ticketId === ticket.ticketId && relationList?.length > 0 && relationList.map((item) => <CustomTableRow key={ticket.ticketCode} ticket={item} type="secondary" />)}
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                        <Table sx={styles.table}>
                            <TableFooter sx={styles.tableFooter}>
                                <TableRow>
                                    <TablePagination
                                        sx={styles.tablePagination}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={list.count}
                                        rowsPerPage={+rowsPerPage}
                                        page={+page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <NoFoundData />
            )}
            {showCreateTicketModal && (
                <CreateTicketModal
                    showModal={showCreateTicketModal}
                    setShowModal={setShowCreateTicketModal}
                    submit={submitCreateTicket}
                />
            )}

            {showTicketSubmissionModal && (
                <ConfirmTicketSubmissionModal
                    showModal={showTicketSubmissionModal}
                    setShowModal={setShowTicketSubmissionModal}
                    ticketDetails={selectedTicket}
                    submit={submitConfirmTicketSubmission}
                />
            )}
            {showCancelTicketModal && (
                <CancelTicketModal
                    showModal={showCancelTicketModal}
                    setShowModal={setShowCancelTicketModal}
                    submit={submitCancelTicket}
                    selectedTicketId={selectedTicket?.ticketId || ""}
                />
            )}
            {showConfirmEntry && (
                <ConfirmEntryModal
                    showModal={showConfirmEntry}
                    setShowModal={setShowConfirmEntry}
                    submit={submitConfirmEntry}
                    selectedTicketCode={selectedTicket?.ticketCode || ""}
                />
            )}
        </Box>
    );
};

export default Tickets;
